import ReactDOM from 'react-dom/client';
import App from './App';
import './globals.css';
import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: 'https://e26d90ceb3547b5d8cf6634383f024c9@o4506513343643648.ingest.us.sentry.io/4507018969677824',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    /^https:\/\/memoorias\.com\//,
    /^https:\/\/app\.memoorias\.com\//,
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment: import.meta.env.MODE,
  enabled: import.meta.env.MODE !== 'development',
});

ReactDOM.createRoot(document.getElementById('root')!).render(<App />);
