import { FaHelmetSafety as HelmetIcon } from 'react-icons/fa6';
import { LuConstruction as ConstructionIcon } from 'react-icons/lu';

const WIPPlaceholder = () => {
  return (
    <div className="flex h-full w-full flex-col justify-center text-zinc-700">
      <div className="mx-auto flex flex-col gap-2 rounded-lg bg-zinc-300 bg-opacity-30 p-4">
        <h1 className="text-center text-2xl font-black">WIP</h1>
        <div className="flex items-center justify-center gap-2">
          <ConstructionIcon className="mx-auto text-5xl text-yellow-500 " />
          <HelmetIcon className="mx-auto text-5xl text-yellow-500" />
        </div>
        <h1 className="text-cl text-center font-medium">En construcción</h1>
      </div>
    </div>
  );
};

export default WIPPlaceholder;
