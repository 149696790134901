import MemooriasLoading from '@/assets/animations/memoorias-loading.svg';

const FullScreenLoading = ({ withAnimation = true }) => {
  return (
    <div className="flex h-screen items-center justify-center">
      {withAnimation && <MemooriasLoading className="size-24 text-primary" />}
    </div>
  );
};

export default FullScreenLoading;
