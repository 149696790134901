import { ReactNode } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import MemooriasLogoSVG from '@/assets/images/memoorias-logo-full.svg';
import { cn } from '@/lib/utils';
import NavMenu from './NavMenu';
import { Button } from './Button';
import useAuth from '../Auth/useAuth';

const NavBar = ({ children }: { children?: ReactNode }) => {
  const { session } = useAuth();
  const account = session?.account;

  const navigate = useNavigate();
  const location = useLocation();

  function handleSignIn() {
    navigate('/auth/sign-in', { state: { onSuccessReturnPath: location.pathname } });
  }

  return (
    <>
      <nav className={cn('z-30 bg-white shadow dark:bg-gray-900')}>
        <div className="mx-auto flex max-w-screen-xl flex-wrap items-center justify-between p-2  sm:p-3 md:p-4">
          <Link
            to={import.meta.env.VITE_BACKEND_ENDPOINT}
            className="max-w-fit flex-[2] drop-shadow-sm"
          >
            <MemooriasLogoSVG className="h-4 w-fit drop-shadow-lg md:h-6" />
          </Link>

          <div className="max-sm:hidden">{children ?? children}</div>

          {account ? (
            <NavMenu />
          ) : (
            <Button variant="ghost" onClick={handleSignIn}>
              Iniciar sesión
            </Button>
          )}

          <div className="mx-auto mt-2 min-w-[50%] sm:hidden">{children ?? children}</div>
        </div>
      </nav>
    </>
  );
};

export default NavBar;
