const Footer = () => {
  return (
    <footer className="bg-white py-4 text-center text-sm text-neutral-700">
      <a href={import.meta.env.VITE_BACKEND_ENDPOINT}>
        <img
          src="/logo.ico"
          alt="Memooria logo"
          width={50}
          height={50}
          className="mx-auto my-2"
        />
      </a>

      <div className="flex flex-wrap justify-center gap-6 md:gap-16">
        <a
          href="https://wa.me/?phone=56981590154"
          rel="noreferrer"
          target="_blank"
          className="hover:underline"
        >
          Contáctanos
        </a>

        <a
          href="https://wa.me/?phone=56981590154&text=¡Hola! Necesito ayuda con mis Memoorias"
          rel="noreferrer"
          target="_blank"
          className="hover:underline"
        >
          Ayuda
        </a>

        <a
          href={`${import.meta.env.VITE_BACKEND_ENDPOINT}/cotiza-gratis`}
          rel="noreferrer"
          target="_blank"
          className="hover:underline"
        >
          Regala
        </a>

        <a
          href={`${import.meta.env.VITE_BACKEND_ENDPOINT}#faqs`}
          rel="noreferrer"
          target="_blank"
          className="hover:underline"
        >
          Preguntas frecuentes
        </a>
      </div>
    </footer>
  );
};

export default Footer;
