import { Outlet } from 'react-router-dom';
import NavBar from '../ui/NavBar';
import MemooryNavTabs from './MemooryNavTabs';
import Footer from '@/components/ui/Footer';
import useAuth from '../Auth/useAuth';
import { queryClient } from '@/App';
import { getTopics } from '@/api/topic';

const Memoory = () => {
  const { session } = useAuth();

  const account = session?.account;

  const isAdmin =
    account && account.status === 'verified' && account?.roles.includes('admin');

  return (
    <>
      <NavBar>{account && isAdmin && <MemooryNavTabs />}</NavBar>
      <div className="flex flex-auto flex-col">
        <div className="mx-auto flex w-full max-w-screen-xl flex-auto flex-col">
          <Outlet />
        </div>
      </div>

      <Footer />
    </>
  );
};

export const topicsLoader = async () => {
  await queryClient.prefetchQuery({ queryKey: ['topics'], queryFn: getTopics });
  return queryClient.getQueryData(['topics']);
};

export default Memoory;
