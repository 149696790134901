import { cn } from '@/lib/utils';

const Skeleton = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <div
      className={cn(
        'animate-pulse-fast rounded-md bg-neutral-600 bg-opacity-10',
        className,
      )}
      {...props}
    />
  );
};

export { Skeleton };
