import apiClient from './axiosConfig';
import { SignInFormData } from '@/components/Auth/SignIn/SignInWithEmail';
import type { EmptyObject } from '@/types/helpers';
import { OauthServiceProvider } from '@/components/Auth/OauthRedirect';
import { EmailSignUpData } from '@/components/Auth/SignUp';
import { VerifyEmailAccountData } from '@/components/Auth/SignUp/VerifyEmailAccount';

async function signUp({ email }: EmailSignUpData) {
  return await apiClient.post(
    'auth/create-account',
    {
      email,
    },
    { withCredentials: true },
  );
}

async function getOauthUrl(provider: OauthServiceProvider) {
  const response = await apiClient.post<EmptyObject, { data: { authorizeUrl: string } }>(
    `auth/oauth/${provider}`,
    {},
    { withCredentials: true },
  );
  return response.data;
}

async function oauthCallback({
  params,
  provider,
}: {
  params: URLSearchParams;
  provider: OauthServiceProvider;
}) {
  return await apiClient.get(`auth/oauth/${provider}/callback`, {
    params,
    data: {},
    withCredentials: true,
  });
}

async function signIn({ email, password }: SignInFormData) {
  return await apiClient.post(
    'auth/login',
    {
      email,
      password,
    },
    {
      withCredentials: true,
    },
  );
}

async function verifyEmailAccount(accountData: VerifyEmailAccountData, token: string) {
  return await apiClient.post(
    'auth/verify-account',
    {
      ...accountData,
      key: token,
    },
    { withCredentials: true },
  );
}

async function signOut() {
  return await apiClient.post(
    'auth/logout',
    { globalLogout: true },
    {
      withCredentials: true,
    },
  );
}

export type Session = {
  account: Account;
  authenticatedBy?: ('password' | 'google' | 'facebook')[];
};

export type Account = UnverifiedAccount | VerifiedAccount;

type AccountBase = {
  id: string;
  email: string;
  status: 'unverified' | 'verified';
  verified: boolean;
  roles: ('admin' | 'user')[];
};

type UnverifiedAccount = {
  status: 'unverified';
} & AccountBase;

export type VerifiedAccount = {
  status: 'verified';
  profile: Profile;
} & AccountBase;

export type Profile = {
  name: string;
  firstName: string;
  lastName: string;
  photo: string;
};

async function getSession() {
  const response = await apiClient.get<Session>('auth/session', {
    withCredentials: true,
  });
  return response.data;
}

export {
  signUp,
  signIn,
  verifyEmailAccount,
  signOut,
  getOauthUrl,
  oauthCallback,
  getSession,
};
