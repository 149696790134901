import { Tabs, TabsList, TabsTrigger } from '@/components/ui/Tabs';
import { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const MemooryNavTabs = () => {
  const navigate = useNavigate();
  const { memooryId } = useParams();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState('profile');

  function handleTabChange(value: string) {
    if (value === 'profile') navigate(`memoories/${memooryId}`, { relative: 'path' });
    else if (value === 'admin')
      navigate(`memoories/${memooryId}/admin/episodes`, { relative: 'path' });
  }

  useEffect(() => {
    if (location.pathname.includes('admin')) {
      setActiveTab('admin');
    } else {
      setActiveTab('profile');
    }
  }, [location.pathname]);

  return (
    <Tabs value={activeTab} defaultValue="profile" onValueChange={handleTabChange}>
      <TabsList className="space-x-2">
        <TabsTrigger value="profile">Perfil</TabsTrigger>
        <TabsTrigger value="admin">Administrar</TabsTrigger>
      </TabsList>
    </Tabs>
  );
};

export default MemooryNavTabs;
