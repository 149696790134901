import { useState } from 'react';
import useAuth from '../Auth/useAuth';
import { Avatar, AvatarFallback, AvatarImage } from './Avatar';
import { buttonVariants } from './Button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuTrigger,
} from './DropdownMenu';
import { Skeleton } from './Skeleton';
import { IoMenu as MenuIcon } from 'react-icons/io5';
import { LuLogOut as SignOutIcon } from 'react-icons/lu';
import { BsHouseHeart as HomeIcon } from 'react-icons/bs';

import { cn } from '@/lib/utils';
import { useNavigate } from 'react-router-dom';

export function NavMenu() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { signOut, session } = useAuth();

  const account = session?.account;

  const navigate = useNavigate();

  function handleSignOut() {
    setIsMenuOpen(false);
    signOut();
  }

  function handleNavigateToHome() {
    navigate('/memoories');
    setIsMenuOpen(false);
  }

  const accountIsVerified = account?.status === 'verified';

  return (
    <DropdownMenu open={isMenuOpen}>
      <DropdownMenuTrigger className="flex cursor-default items-center justify-end space-x-3 focus-visible:outline-none max-sm:min-w-[50%] md:order-2 md:space-x-0">
        {account ? (
          <div className="inline-flex items-center space-x-4">
            <h3 className="hidden md:block">
              {accountIsVerified && (
                <>
                  ¡Hola,{' '}
                  <span className="font-semibold">{account.profile.firstName}</span>!
                </>
              )}
            </h3>

            <div
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className={cn(
                buttonVariants({
                  variant: 'ghost',
                  size: 'icon',
                  className: 'cursor-pointer rounded-full',
                }),
              )}
            >
              <Avatar>
                {accountIsVerified ? (
                  <>
                    <AvatarImage src={account.profile.photo} />
                    <AvatarFallback className="">
                      {account.profile.firstName[0]}
                    </AvatarFallback>
                  </>
                ) : (
                  <AvatarFallback className="text-primary">!</AvatarFallback>
                )}
              </Avatar>
            </div>

            <div
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className={cn(
                buttonVariants({
                  variant: 'ghost',
                  size: 'icon',
                  className: 'cursor-pointer md:hidden',
                }),
              )}
            >
              <MenuIcon className="size-4" />
            </div>
          </div>
        ) : (
          <Skeleton className="size-10 rounded-full" />
        )}
      </DropdownMenuTrigger>

      <DropdownMenuContent
        onInteractOutside={() => setIsMenuOpen(false)}
        className="w-48"
        align="end"
        forceMount
      >
        <DropdownMenuGroup>
          <DropdownMenuItem className="cursor-pointer" onSelect={handleNavigateToHome}>
            Mis Memoorias
            <DropdownMenuShortcut>
              <HomeIcon className="size-4" />
            </DropdownMenuShortcut>
          </DropdownMenuItem>

          <DropdownMenuSeparator />

          <DropdownMenuItem className="cursor-pointer" onSelect={handleSignOut}>
            Cerrar sesión
            <DropdownMenuShortcut>
              <SignOutIcon className="size-4" />
            </DropdownMenuShortcut>
          </DropdownMenuItem>
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

export default NavMenu;
