import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import MemooriasLoading from '@/assets/animations/memoorias-loading.svg';
import { cn } from '@/lib/utils';

const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium duration-150 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 hover:scale-105 transition-all ease-in-out select-none active:scale-95',
  {
    variants: {
      variant: {
        default: 'bg-primary text-primary-foreground shadow hover:bg-primary/80',
        destructive:
          'bg-destructive text-destructive-foreground shadow-sm hover:bg-destructive/90',
        outline:
          'border border-primary bg-background shadow-sm hover:bg-accent text-primary hover:bg-primary/5',
        secondary:
          'bg-secondary text-secondary-foreground shadow-sm hover:bg-secondary/80',
        ghost: 'text-neutral-500 hover:text-primary hover:bg-accent hover:bg-opacity-5',
        link: 'text-primary underline-offset-4 hover:underline',
        subtleGhost:
          'text-neutral-500 hover:text-primary hover:bg-accent hover:bg-opacity-5',
      },
      size: {
        default: 'h-9 px-4 py-2 hover:scale-[1.02]',
        sm: 'h-8 rounded-md px-3 text-xs hover:scale-[1.03]',
        lg: 'h-10 text-base rounded-md px-8 hover:scale-[1.01]',
        icon: 'h-9 w-9 hover:scale-110',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  isLoading?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, isLoading, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button';
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        disabled={props.disabled || isLoading}
        {...props}
      >
        <span className={cn('visible contents', { invisible: isLoading })}>
          {props.children}
        </span>
        <MemooriasLoading
          className={cn('invisible absolute size-8', { visible: isLoading })}
        />
      </Comp>
    );
  },
);
Button.displayName = 'Button';

export { Button, buttonVariants };
