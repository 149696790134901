import AppRouterProvider from '@/providers/AppRouterProvider';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AuthProvider } from '@/providers/AuthProvider';
import { Toaster } from 'sonner';
import { TooltipProvider } from './components/ui/Tooltip';

export const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <TooltipProvider delayDuration={300}>
        <AuthProvider>
          <AppRouterProvider />
        </AuthProvider>
        <Toaster closeButton richColors duration={3000} />
      </TooltipProvider>
    </QueryClientProvider>
  );
}

export default App;
