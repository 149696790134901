import apiClient from '@/api/axiosConfig';

export type Topic = {
  id: number;
  name: string;
  icon?: string;
  description?: string;
};

async function getTopics() {
  const response = await apiClient.get<Topic[]>('/topics/index');
  return response.data;
}

export { getTopics };
